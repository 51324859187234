import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import resolveRelationships from 'core/utils/relationships';
import { processQueryResults } from 'core/utils/query';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card5 from 'site/cards/Card5';
import Card6 from 'site/cards/Card6';
import CardHorizontal from 'site/cards/CardHorizontal';

const selectionRelationships = resolveRelationships(['read_more'], {});

const card2Params = {
  fields: filterRequiredParams([Card2], 'fields'),
};

const card6Params = {
  fields: filterRequiredParams([Card6], 'fields'),
  include: filterRequiredParams([Card6], 'include'),
};

const cardHorizontalParams = {
  fields: filterRequiredParams([CardHorizontal], 'fields'),
  include: filterRequiredParams([CardHorizontal], 'include'),
};

export const reviewsCommonParams = {
  rubric_root: 'review',
  visibility: 'main_page',
  fields: filterRequiredParams([Card3], 'fields'),
  include: filterRequiredParams([Card3], 'include'),
};

function getFirstBlock(mainTopics, newsTopics) {
  return {
    newsTopics,
    mainTopics,
  };
}

function getSelections(rawSelections) {
  const selection = rawSelections?.[0];
  const selectionTopics = selectionRelationships(selection)?.read_more?.read_more_topics || [];

  if (selectionTopics?.length < 1) return {};

  return {
    selection,
    topics: selectionTopics.slice(0, 6),
  };
}

function getNumberBlock(rawNumber) {
  return rawNumber?.[0] || null;
}


export default function useMainContent(isDesktop, history) {
  // mainTopics
  const {
    data: mainTopics,
    isLoading: mainTopicsAreLoading,
  } = useRequest(
    topicsQuery({
      list: 'main',
      sort: 'list',
      autocompleted_list_limit: 4,
      include: 'image,review',
      fields: filterRequiredParams([Card1, Card3], 'fields'),
      history,
    }, {
      select: denormalizeData,
    }),
  );

  // newsTopics
  const {
    data: newsTopics,
    isLoading: newsTopicsAreLoading,
  } = useRequest(
    topicsQuery({
      limit: 11,
      topic_type: 'news',
      visibility: 'main_page',
      excluded_ids: mainTopics?.map(item => item.id).join(','),
      history,
      ...card2Params,
    }, {
      enabled: !mainTopicsAreLoading,
      select: denormalizeData,
    }),
  );

  const results = useRequest({
    queries: [
      // rawSelection
      topicsQuery({
        limit: 1,
        list: 'selection',
        sort: 'list',
        include: 'read_more,tags,image',
        fields: 'headline,published_at,link,ad_label',
        history,
      }),
      // mostCommentable
      topicsQuery({
        limit: 6,
        /* Сортировка по 'comments_count' не поддерживает параметры: 'visibility'*/
        sort: '-comments_count',
        fields: 'headline,published_at,link,ad_label',
        history,
      }),
      // reviews
      topicsQuery({
        ...reviewsCommonParams,
        limit: isDesktop ? 6 : 4,
        history,
      }),
      // experts
      topicsQuery({
        list: 'experts',
        sort: 'list',
        autocompleted_list_limit: 3,
        history,
        ...cardHorizontalParams,
        include: 'image,authors,rubric',
      }),
      // videos
      topicsQuery({
        list: 'video',
        sort: 'list',
        autocompleted_list_limit: 4,
        include: 'image,rubric',
        fields: filterRequiredParams([Card3, Card5], 'fields'),
        history,
      }),
      // numberOfTheDay
      topicsQuery({
        list: 'number',
        limit: 1,
        history,
        ...card6Params,
      }),
      // historyNumber
      topicsQuery({
        list: 'history',
        limit: 1,
        history,
        ...card6Params,
      }),
      // lifehacks
      topicsQuery({
        visibility: 'main_page',
        rubric_root: 'lifehack',
        limit: isDesktop ? 3 : 4,
        history,
        ...cardHorizontalParams,
      }),
    ],
  });

  const {
    data: [
      rawSelection,
      mostCommentable,
      reviews,
      experts,
      videos,
      numberOfTheDay,
      historyNumber,
      lifehacks,
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  if (mainTopicsAreLoading || newsTopicsAreLoading || resultsAreLoading) {
    return { isLoading: true };
  }

  return {
    firstBlock: getFirstBlock(mainTopics, newsTopics),
    selection: getSelections(rawSelection),
    mostCommentable,
    reviews,
    experts,
    videos,
    numberOfTheDay: getNumberBlock(numberOfTheDay),
    historyNumber: getNumberBlock(historyNumber),
    lifehacks,
  };
}
