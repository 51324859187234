import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { bebop as bebopApi } from 'core/api/definitions/bebop';

import { denormalizeData } from 'core/utils/api';

import bindProps from 'core/components/bindProps';
import withTabs from 'core/components/withTabs';
import GridFeed from 'core/components/GridFeed';

import MainBlockTitle from 'site/components/MainBlockTitle';
import Card3 from 'site/cards/Card3';

import { SMALL_INDENT } from 'site/constants';
import { reviewsCommonParams } from 'site/pages/main/useMainContent';

import DesktopSkeleton from './DesktopSkeleton';

const Card3Type3 = bindProps({ type: 3 })(Card3);
const tabs = [
  { title: 'Все' },
  { rubric: 'notebooks', title: 'Ноутбуки и планшеты' },
  { rubric: 'mobile', title: 'Телефоны' },
  { rubric: 'multimedia', title: 'Фото и видео' },
];


function TabContent({ rubric }) {
  const {
    data: content,
    isLoading,
    isError,
  } = useRequest({
    queryKey: ['bebopApi', 'getTopics', {
      ...reviewsCommonParams,
      rubric,
      limit: 6,
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params)
      .catch(err => console.error(err)),
    select: denormalizeData,
  });

  if (isLoading || isError) return <DesktopSkeleton failed={isError} />;

  return (
    <GridFeed
      content={content}
      dataQa='feed-reviews'
      card={Card3Type3}
      gap={SMALL_INDENT + 'px'}
      gridTemplateColumns='1fr 1fr 1fr'
    />
  );
}

export default withTabs({
  tabs,
  beforeGap: 40,
  before: <MainBlockTitle marginBottom={3}>Обзоры</MainBlockTitle>,
})(TabContent);

TabContent.propTypes = {
  rubric: PropTypes.string,
};
