import deepmerge from 'core/libs/deepmerge';
import color from 'core/libs/color';

import {
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import { CardHorizontalMobile } from 'site/cards/CardHorizontal';

import fonts from './fonts';


export default function baseMobileTheme(atoms) {
  const texts = {
    // Header страницы
    headline: {
      font: `700 22px/1.22 ${fonts.text}`,
      color: atoms.colors.primary,
    },
    // Заголовки в теле топиков
    get title1() {
      return this.headline;
    },
    title2: {
      font: `700 20px/1.4 ${fonts.text}`,
    },
    title3: {
      font: `700 18px/1.2 ${fonts.text}`,
    },
    title4: {
      font: `600 16px/1.2 ${fonts.text}`,
    },
    // Прочие стили текстов в теле топика
    body: {
      font: `16px/1.5 ${fonts.text}`,
    },
    bodySmall: {
      font: `14px/1.35 ${fonts.text}`,
    },
    lead: {
      font: `400 20px/30px ${fonts.text}`,
      color: atoms.colors.primary,
      marginTop: '15px',
    },
    incut: {
      font: `600 16px/22px ${fonts.text}`,
    },
    opinion: {
      font: `300 italic 18px/23px ${fonts.text}`,
    },
    number: {
      body: {
        font: `300 italic 16px/20px ${fonts.text}`,
      },
      value: {
        font: `bold 74px/100px ${fonts.text}`,
      },
    },
    stickyPlateCard: {
      font: `bold 12px ${fonts.text}`,
    },
    // Заголовки на сайте, вне тела топиков
    h1: {
      font: `700 24px/1.5 ${fonts.text}`,
      letterSpacing: '-0.8px',
    },
    h2: {
      font: `700 24px/1.5 ${fonts.text}`,
      letterSpacing: 'normal',
      margin: 0,
    },
    h3: {
      font: `700 20px/1.5 ${fonts.text}`,
      letterSpacing: '-0.8px',
    },
    h4: {
      font: `700 18px/1.5 ${fonts.text}`,
      letterSpacing: 'normal',
    },
    // Заголовки блоков на главной
    display1: {
      font: `700 20px/1.2 ${fonts.text}`,
    },
    // Заголовки в бороде
    display2: {
      font: `700 24px/1.2 ${fonts.text}`,
    },
    // Заголовки блоков в сайдбаре
    display3: {
      font: `700 18px/normal ${fonts.text}`,
      letterSpacing: '0.1px',
    },
    // Время, дата, рубрика
    caption1: {
      font: `400 14px/normal ${fonts.text}`,
    },
    // Подпись под фото и видео в топиках
    caption2: {
      font: `700 14px/normal ${fonts.text}`,
    },
    // Имя автора в начале и в конце топика, спонсорство
    caption4: {
      font: `400 16px/1.38 ${fonts.text}`,
    },
    // Пункты меню и в футере
    caption5: {
      font: `700 18px/1.5 ${fonts.text}`,
    },
    // Имя автора в AuthorCard
    author: {
      font: `700 22px/28px ${fonts.text}`,
    },
    // Метаинфа в горизонтальных карточках
    cardsMeta: {
      font: `400 12px/15px ${fonts.text}`,
      color: atoms.colors.gray600,
    },
  };

  const vikontReadMore = {
    block: {
      padding: '15px',
      borderRadius: 0,
    },
    drum: {
      itemWidth: 282,
      spaceBetween: 20,
    },
    title: {
      marginBottom: '15px',
    },
  };

  const vikontIncut = {
    padding: '15px',
  };

  const widgetCaption = {
    textAlign: 'left',
    caption: {
      font: `14px/18px ${fonts.text}`,
      color: atoms.colors.primary400,
    },
    credits: {
      font: `14px/18px ${fonts.text}`,
      color: atoms.colors.primary400,
    },
  };

  const scooter = {
    padding: `${VERTICAL_INDENT_MOBILE}px ${SIDE_INDENT_MOBILE}px ${VERTICAL_INDENT_MOBILE * 3}px`,
    logo: {
      width: '140px',
      height: '33px',
    },
    menuLink: {
      font: `600 16px/18px ${fonts.text}`,
    },
    social: {
      size: 33,
      iconSize: 19,
    },
  };

  const topicFooter = {
    commentButton: {
      topSpacing: VERTICAL_INDENT_MOBILE,
    },
    block: {
      interitemSpacing: 10,
      spacing: VERTICAL_INDENT_MOBILE,
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 16px/22px ${fonts.text}`,
      },
      info: {
        font: `400 14px/20px ${fonts.text}`,
      },
    },
  };

  const shapka = {
    padding: `0 ${SIDE_INDENT_MOBILE}px`,
    logo: {
      width: 149,
      height: 35,
    },
  };

  const commentsPreview = {
    headline: {
      font: `bold 22px/1 ${fonts.text}`,
    },
    name: {
      font: `bold 14px/18px ${fonts.text}`,
    },
    button: {
      margin: '25px 0 20px 0',
    },
  };

  const errorPage = {
    imgWidth: 230,
    title: {
      font: `bold 70px/95px ${fonts.text}`,
    },
    subtitle: {
      font: `bold 20px/27px ${fonts.text}`,
    },
    card: CardHorizontalMobile,
  };

  const pages = {
    error: errorPage,
    notFound: errorPage,
    sentryFallback: {
      ...errorPage,
      imgWidth: 220,
      title: {
        font: `bold 54px/54px ${fonts.text}`,
      },
      card: null,
    },
  };

  const authorPage = {
    card: {
      name: {
        font: texts.author.font,
      },
    },
  };

  const storyWidgetsBaseCard = {
    headline: {
      font: `600 22px/27px ${fonts.display}`,
    },
  };

  const layout = {
    indents: {
      top: VERTICAL_INDENT_MOBILE,
      right: SIDE_INDENT_MOBILE,
      bottom: VERTICAL_INDENT_MOBILE,
      left: SIDE_INDENT_MOBILE,
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `700 24px/1.25 ${fonts.text}`,
      letterSpacing: 'normal',
      color: atoms.colors.primary,
    },
    subtitle: {
      font: `400 24px/1.25 ${fonts.text}`,
      letterSpacing: 'normal',
      color: color(atoms.colors.primary).alpha(0.6).string(),
    },
    lead: {
      ...texts.lead,
    },
    divider: {
      margin: '15px calc(100% - 300px) 15px 0',
      background: color(atoms.colors.primary).alpha(0.15).string(),
    },
  };

  const gameCompare = {
    share: {
      showBorder: true,
      marginTop: 0,
    },
    leaderBoard: {
      modalWindow: {
        color: atoms.colors.primary,
      },
      ratings: `700 13px/18px ${fonts.text}`,
    },
    concidences: {
      font: `400 15px/23px ${fonts.text}`,
    },
  };

  const tags = {
    margin: '0 -15px',
    padding: '0 0 0 15px',
  };

  const about = {
    headline: {
      font: texts.headline.font,
    },
    title2: {
      font: texts.title2.font,
    },
  };

  return deepmerge({
    controls: {
      vikontReadMore,
      vikontIncut,
      widgetCaption,
      scooter,
      shapka,
      authorPage,
      topicFooter,
      topicHeaderTexts,
      commentsPreview,
      storyWidgetsBaseCard,
      smartTeaser,
      gameCompare,
      tags,
      about,
    },
    pages,
    layout,
    texts,
  }, atoms);
}
