import PropTypes from 'prop-types';


export default function Author(props) {
  const {
    author,
    theme: {
      colors: {
        primary,
        gray600,
      },
    },
  } = props;

  if (!author) return null;

  const {
    name,
    job_title: jobTitle,
  } = author?.attributes || {};

  return (
    <div className='author'>
      <style jsx>{`
        .author
          color ${primary}
        .jobTitle
          color ${gray600}
      `}</style>
      {name}{jobTitle && ', '}
      {jobTitle && (
        <span className='jobTitle'>{jobTitle}</span>
      )}
    </div>
  );
}

Author.propTypes = {
  author: PropTypes.object,
  theme: PropTypes.object,
};
