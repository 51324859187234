import GlobalStyles from '../global';
import './index.styl';

export default function ListingSpec(Component) {
  return function ListingSpecComponent(props) {
    return (
      <div className='native-ad listing-spec'>
        <GlobalStyles />
        <Component {...props} />
      </div>
    );
  };
}
