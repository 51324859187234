import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'core/components/Link';
import Avatar from 'core/components/Avatar';
import AuthorTypeLabel from 'core/components/AuthorTypeLabel';

import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';

import styles from './index.styl';


export default function Info({
  author,
  noBio,
  isDesktop,
}) {
  const {
    email,
    type,
    avatar,
    job_title: jobTitle,
  } = author.attributes;

  const userAvatar = avatar?.attributes?.versions?.original;
  const noDefaultAvatar = noBio && !userAvatar;

  return (
    <div className={cx(styles.info, noDefaultAvatar && styles._noAvatar)}>
      {!noDefaultAvatar && (
        <div className={styles.avatar}>
          <Avatar
            src={userAvatar}
            size={isDesktop ? 80 : 120}
            isCircular
          />
        </div>
      )}
      <div className={styles.text}>
        {type === 'expert' && (
          <div data-qa='author-type'>
            <AuthorTypeLabel type={type} />
          </div>
        )}
        {!!jobTitle && (
          <div data-qa='author-job-title'>
            {jobTitle}
          </div>
        )}
        {!!email && (
          <Link
            to={`mailto:${email}`}
            dataQa='author-email'
            type='primaryUnderlined'
          >
            {email}
          </Link>
        )}
      </div>
    </div>
  );
}

Info.propTypes = {
  author: modelPropTypes(personAttributes).isRequired,
  noBio: PropTypes.bool,
  isDesktop: PropTypes.bool,
};
