import cx from 'classnames';
import PropTypes from 'prop-types';

import Image from 'core/components/SmartImage';

import cardHOC from 'core/components/card';

import resolveRelationships from 'core/utils/relationships';
import relationshipsPropTypes, { imagePropTypes } from 'core/utils/prop-types/relationships';

import PremiumStory from 'site/icons/PremiumStory';

import styles from './index.styl';

const relationships = resolveRelationships(
  ['image'],
  {},
  { image: { versions: {} } },
);

/**
 * Максимальная ширина изображения, чтобы не очень много и не мало.
 * @type {number}
 */
const IMAGE_WIDTH = 145;
const IMAGE_HEIGHT = 190;

/**
 * Базовая карточка для стори-топика.
 */
function StoryTopicCard(props) {
  const { content, theme } = props;

  const {
    is_premium: isPremium,
    headline,
    list_headline: listHeadline,
  } = content?.attributes || {};

  const {
    image: {
      versions,
    },
  } = relationships(content);

  const {
    colors: {
      content: contentColor,
      primary,
      active1,
    },
    fonts: {
      text: textFont,
    },
  } = theme;

  return (
    <div className={cx(styles.baseCard, isPremium && styles._isPremium)}>
      <style jsx>{`
        .${styles.headline}
          color ${contentColor}
          font 700 11px/13px ${textFont}

          span
            .${styles._isPremium} &
              color ${primary}
              background ${active1}
              font italic 700 12px/14px ${textFont}
      `}</style>
      <div className={styles.image}>
        <Image
          versions={versions}
          maxWidth={IMAGE_WIDTH}
          aspectRatio={IMAGE_WIDTH / IMAGE_HEIGHT}
          instant
        />
      </div>
      <div className={styles.content}>
        <div className={styles.headline}>
          <span>
            {listHeadline || headline}
          </span>
        </div>
      </div>
      {isPremium && <PremiumStory className={styles.premiumIcon} />}
    </div>
  );
}

StoryTopicCard.propTypes = {
  /** @ignore */
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  /** @ignore */
  theme: PropTypes.object,
};

const StoryTopicCardWithHOCs = cardHOC(StoryTopicCard);
StoryTopicCardWithHOCs.displayName = 'StoryTopicCard';

export default StoryTopicCardWithHOCs;

export { StoryTopicCard as StorybookComponent };
